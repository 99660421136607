<template>
  <div class="elv-project-settings-members-content">
    <div class="elv-project-settings-members-header">
      <p>{{ t('common.members') }}({{ projectStore.projectMemberList.length }})</p>
      <elv-button
        v-if="
          projectStore.projectDetail?.userCount &&
          projectStore.projectMemberList.length < projectStore.projectDetail?.userCount
        "
        round
        plain
        type="primary"
        width="119"
        height="32"
        :disabled="!projectStore.projectEntityList.length"
        @click="onAddMember"
      >
        <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addMember') }}
      </elv-button>
      <p v-else class="elv-project-settings-members-header__upgrade">
        {{ t('message.needMoreMembers') }}<span @click="onJumpPrice">{{ t('message.upgradePro') }}</span>
      </p>
    </div>
    <ul class="elv-project-settings-members-list">
      <li v-for="(member, index) in projectStore.projectMemberList" :key="index">
        <div class="elv-project-settings-members-item__user">
          <VueBlockies
            v-if="member.name"
            style="border-radius: 50%"
            :seed="md5UserParams(member?.userId)"
            :color="changeAvatarColor(md5UserParams(member?.userId))[0]"
            :bgcolor="changeAvatarColor(md5UserParams(member?.userId))[1]"
            :size="7"
            :scale="4"
            spot-color="#666"
          />
          <img v-else src="@/assets/img/member-invite-avatar.png" alt="avatar" />
          <div class="elv-project-settings-members-item__info">
            <p class="elv-project-settings-members-item__info-name">{{ member.name }}</p>
            <p class="elv-project-settings-members-item__info-email">
              {{ member.email
              }}<span v-if="showInvitation(member)" @click="onResendInvitationEmail(member.userId)">{{
                t('button.resendInvitation')
              }}</span>
            </p>
          </div>
        </div>
        <div class="elv-project-settings-members-item__operating">
          <div
            v-if="
              member?.userId === projectStore.projectDetail?.userId || ['SUPER_ADMIN', 'ADMIN'].includes(member?.role)
            "
            class="elv-project-settings-members-item__operating--role"
          >
            {{ t('common.administrator') }}
          </div>
          <template v-else>
            <div class="elv-project-settings-members-item__operating--entity">
              <img
                v-for="(item, i) in member.entityList"
                :key="i"
                :src="item.logo"
                :alt="item.name"
                :onerror="useDefaultImage"
              />
            </div>
          </template>
          <div v-if="member?.role !== 'SUPER_ADMIN'" class="elv-project-settings-members-item__operating--button">
            <SvgIcon name="sources-edit" width="18" height="18" @click="onEditMember(member)" />
            <SvgIcon name="sources-delete" width="18" height="18" @click="onDeleteMember(member)" />
          </div>
        </div>
      </li>
    </ul>
  </div>

  <ElvMessageBox
    ref="deleteMemberRef"
    :confirm-button-text="t('button.delete')"
    :cancel-button-text="t('button.cancel')"
    :title="t('common.deleteMember')"
    :loading="deleteMemberLoading"
    @onConfirmEvent="onConfirmDeleteMember"
    @onCancelEvent="onCloseConfirm"
  >
    <template #content>
      <span class="elv-delete-member-confirm-header__title">{{
        t('message.deleteMemberMessage', { email: currentEntityMemberData?.email })
      }}</span></template
    >
  </ElvMessageBox>

  <MemberForm ref="memberFormDialogRef" :model="model" :current-data="currentEntityMemberData" />
</template>

<script setup lang="ts">
import md5 from 'js-md5'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import VueBlockies from 'vue-blockies'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import MemberForm from './MemberForm.vue'
import { avatarColor } from '@/config/index'
import { useProjectStore } from '@/stores/modules/project'
import defaultImg from '@/assets/img/reports/default-entity-logo.png'

dayjs.extend(utc)
const { t } = useI18n()
const route = useRoute()
const projectStore = useProjectStore()

const model = ref('add')
const currentEntityMemberData = ref()
const deleteMemberLoading = ref(false)
const deleteMemberRef = useTemplateRef('deleteMemberRef')
const memberFormDialogRef = useTemplateRef('memberFormDialogRef')

const md5UserParams = computed(() => {
  return (userId: string) => {
    const md5UserId = userId.slice(0, 18)
    return md5(md5UserId)
  }
})

const projectId = computed(() => {
  return String(route.params?.projectId)
})

const showInvitation = computed(() => {
  return (member: any) => {
    return !member.name && dayjs().utc(true).diff(member.updatedAt, 'hour') > 24
  }
})

const changeAvatarColor: any = (val: string) => {
  const lastStr = val.substr(val.length - 1, 1)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(lastStr))) {
    return changeAvatarColor(val.substr(0, val.length - 1))
  }
  return avatarColor[lastStr]
}

const onResendInvitationEmail = async (userId: string) => {
  try {
    await ProjectApi.resendInvitationEmail(projectId.value, userId)
    projectStore.fetchProjectMemberList(projectId.value)
    ElMessage.success(t('message.resendInvitationEmailSuccess'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  }
}

const onJumpPrice = () => {
  window.open('https://www.elven.com/pricing', '_blank')
}

const onConfirmDeleteMember = async () => {
  try {
    deleteMemberLoading.value = true
    await ProjectApi.deleteProjectMember(projectId.value, currentEntityMemberData.value?.userId)
    ElMessage.success(t('message.deleteSuccess'))
    deleteMemberLoading.value = false
    projectStore.fetchProjectMemberList(projectId.value)
    projectStore.fetchProjectDetail(projectId.value)
    deleteMemberRef.value?.onCheckMessageBoxDialog()
  } catch (error: any) {
    console.log(error)
    deleteMemberLoading.value = false
    ElMessage.error(error?.message)
  }
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onCloseConfirm = () => {
  deleteMemberRef.value?.onCheckMessageBoxDialog()
}

const onEditMember = (memberData: any) => {
  model.value = 'edit'
  currentEntityMemberData.value = memberData
  memberFormDialogRef.value?.onCheckMemberDialog()
}

const onAddMember = () => {
  model.value = 'add'
  currentEntityMemberData.value = {}
  memberFormDialogRef.value?.onCheckMemberDialog()
}

const onDeleteMember = (memberData: any) => {
  currentEntityMemberData.value = memberData
  deleteMemberRef.value?.onCheckMessageBoxDialog()
}
</script>

<style lang="scss" scoped>
.elv-delete-member-confirm-header__title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1e2024;
}

.elv-project-settings-members-content {
  width: 700px;
  margin-top: 32px;

  .elv-project-settings-members-header {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    :deep(.elv-button) {
      &.is-disabled {
        svg {
          fill: #838d95 !important;
        }
      }
    }

    p {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #0e0f11;
    }

    .elv-project-settings-members-header__upgrade {
      color: #636b75;
      font-size: 12px;
      line-height: auto;
      font-weight: 400;

      span {
        color: #1343bf;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .elv-project-settings-members-list {
    width: 100%;
    border-bottom: 1px solid #edf0f3;
    border-top: 1px solid #edf0f3;

    li {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 68px;
      border-bottom: 1px solid #edf0f3;

      &:last-child {
        border-bottom: none;
      }

      .elv-project-settings-members-item__user {
        display: flex;
        align-items: center;

        img {
          width: 36px;
          height: 36px;
          display: block;
        }
      }

      .elv-project-settings-members-item__info {
        margin-left: 8px;
        display: flex;
        flex-direction: column;

        .elv-project-settings-members-item__info-name {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #0e0f11;
        }

        .elv-project-settings-members-item__info-email {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #838d95;

          span {
            color: #5e85eb;
            margin-left: 8px;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      .elv-project-settings-members-item__operating {
        display: flex;
        align-items: center;
        gap: 16px;

        .elv-project-settings-members-item__operating--button {
          display: flex;
          align-items: center;

          svg {
            fill: #838d95;
            cursor: pointer;
            transition: all 0.1s;

            &:last-child {
              margin-left: 16px;
            }

            &:hover {
              fill: #1e2024;
            }
          }
        }

        .elv-project-settings-members-item__operating--entity {
          display: flex;

          img {
            width: 18px;
            height: 18px;
            display: block;
            margin-right: 4px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .elv-project-settings-members-item__operating--role {
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 24px;
          border: 1px solid #dde1e6;
          border-radius: 2px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #838d95;
        }
      }
    }
  }
}
</style>

<template>
  <div class="elv-project-setting-security">
    <div class="elv-project-setting-security-screen">
      <el-popover
        ref="typePopover"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-project-setting-security-screening-popper"
        :show-arrow="false"
        :offset="0.2"
      >
        <template #reference>
          <div class="elv-project-setting-security-screening-button">
            <SvgIcon
              class="elv-project-setting-security-screening-button__icon"
              name="project-setting-cursor"
              width="16"
              height="16"
            />
            <div class="elv-project-setting-security-screening-button__title">
              {{ transformI18n(find(typeOptions, { value: securityParams.type })?.label) }}
            </div>
          </div>
        </template>
        <el-scrollbar max-height="384px">
          <li v-for="(item, index) in typeOptions" :key="index" @click="onChangeType(item)">
            {{ transformI18n(item.label) }}
          </li>
        </el-scrollbar>
      </el-popover>
      <el-popover
        v-if="projectStore.projectEntityList.length > 1"
        ref="entityPopover"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-project-setting-security-screening-popper"
        :show-arrow="false"
        :offset="0.2"
      >
        <template #reference>
          <div class="elv-project-setting-security-screening-button">
            <SvgIcon
              class="elv-project-setting-security-screening-button__icon"
              name="building"
              width="16"
              height="16"
            />
            <div class="elv-project-setting-security-screening-button__title">
              {{ find(entityOptions, { value: securityParams.entityId })?.label }}
            </div>
          </div>
        </template>
        <el-scrollbar max-height="384px">
          <li v-for="(item, index) in entityOptions" :key="index" @click="onChangeEntity(item)">
            <SvgIcon v-if="item?.value === '0'" name="building" width="20" height="20" />
            <template v-else>
              <img :src="item.logo" :alt="item.name" style="border-radius: 50%" />
            </template>
            {{ item?.label }}
          </li>
        </el-scrollbar>
      </el-popover>
      <el-popover
        ref="memberPopover"
        placement="bottom-start"
        trigger="click"
        popper-class="elv-project-setting-security-screening-popper"
        :show-arrow="false"
        :offset="0.2"
      >
        <template #reference>
          <div class="elv-project-setting-security-screening-button">
            <SvgIcon
              class="elv-project-setting-security-screening-button__icon"
              name="project-setting-user"
              width="16"
              height="16"
            />
            <div class="elv-project-setting-security-screening-button__title">
              {{ find(memberOptions, { value: securityParams.member })?.label }}
            </div>
          </div>
        </template>
        <el-scrollbar max-height="384px">
          <li v-for="(item, index) in memberOptions" :key="index" @click="onChangeMember(item)">
            <SvgIcon v-if="item?.value === 'ALL'" name="project-setting-user" width="20" height="20" />
            <template v-else>
              <VueBlockies
                v-if="item?.label"
                style="border-radius: 50%"
                :seed="md5UserParams(item?.value)"
                :color="changeAvatarColor(md5UserParams(item?.value))[0]"
                :bgcolor="changeAvatarColor(md5UserParams(item?.value))[1]"
                :size="7"
                :scale="4"
                spot-color="#666"
              />
              <img v-else src="@/assets/img/member-invite-avatar.png" alt="avatar" />
            </template>
            {{ item?.label === '' ? item?.email : item?.label }}
          </li>
        </el-scrollbar>
      </el-popover>
    </div>
    <el-table
      ref="securityTableRef"
      v-loading="tableLoading"
      :data="tableData.list"
      border
      height="calc(100vh - 245px)"
      class="elv-project-setting-security-table"
      header-cell-class-name="elv-project-setting-security-table-header__cell"
      header-row-class-name="elv-project-setting-security-table-header"
      row-class-name="elv-project-setting-security-table-row"
      cell-class-name="elv-project-setting-security-table-row__cell"
    >
      <el-table-column width="150" :label="t('common.dateTime')">
        <template #default="{ row }">
          <div class="elv-project-setting-security-table-row__cell-date">
            {{
              row?.createdAt
                ? dayjs(row?.createdAt)
                    .tz(row?.entity?.timezone ?? 'UTC')
                    .format('YYYY/MM/DD HH:mm:ss')
                : '-'
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('title.securityActivity')">
        <template #default="{ row }">
          <div class="elv-project-setting-security-table-row__cell-activity">{{ activityContent(row) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="projectStore.projectEntityList.length > 1"
        prop="entity"
        :label="t('common.entity')"
        width="180"
      >
        <template #default="{ row }">
          <div v-if="row?.entity?.name" class="elv-project-setting-security-table-row__cell-member">
            <img :src="row?.entity?.logo" :alt="row?.entity?.name" />
            <p>{{ row?.entity?.name }}</p>
          </div>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column prop="address" :label="t('common.member')" width="180">
        <template #default="{ row }">
          <div class="elv-project-setting-security-table-row__cell-member">
            <template v-if="row?.openApiAccessId !== '0'">OpenAPI</template>
            <template v-else>
              <VueBlockies
                v-if="row?.user?.name"
                style="border-radius: 50%"
                :seed="md5UserParams(row?.user?.userId)"
                :color="changeAvatarColor(md5UserParams(row?.user?.userId))[0]"
                :bgcolor="changeAvatarColor(md5UserParams(row?.user?.userId))[1]"
                :size="7"
                :scale="4"
                spot-color="#666"
              />
              <img v-else src="@/assets/img/member-invite-avatar.png" alt="avatar" />
              <p>{{ row?.user?.name }}</p>
            </template>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="requestIp" label="IP" min-width="130" width="165" />
      <el-table-column prop="client" :label="t('common.client')" />
    </el-table>
    <elv-pagination
      class="elv-project-setting-pagination"
      size-popper-class="elv-project-setting-pagination__sizes-popper"
      :limit="securityParams.limit"
      :current-page="securityParams.page"
      :total="tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>
</template>

<script lang="ts" setup>
import md5 from 'js-md5'
import dayjs from 'dayjs'
import { find } from 'lodash-es'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import VueBlockies from 'vue-blockies'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { avatarColor } from '@/config/index'
import { middleEllipsis } from '@/lib/utils'
import timezone from 'dayjs/plugin/timezone'
import { $t, transformI18n } from '@/i18n/index'
import { useProjectStore } from '@/stores/modules/project'
import { useReportStore } from '@/stores/modules/reports/index'
import { ProjectSecurityLogType, ProjectSecurityLogItemType, ProjectSecurityLogParamsType } from '#/ProjectTypes'

const props = defineProps({
  width: {
    type: String,
    default: '100%'
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
// eslint-disable-next-line no-unused-vars
const reportStore = useReportStore()
const projectStore = useProjectStore()

const typePopover = useTemplateRef('typePopover')
const securityTableRef = useTemplateRef('securityTableRef')
const entityPopover = useTemplateRef('entityPopover')
const memberPopover = useTemplateRef('memberPopover')
const tableLoading = ref(false)
const typeOptions: any = ref([
  { label: $t('common.allActivity'), value: 'ALL' },
  { label: $t('common.login'), value: 'LOGIN' },
  { label: $t('common.source'), value: 'SOURCE' },
  { label: $t('report.transaction'), value: 'TRANSACTION' },
  { label: $t('report.Journal'), value: 'JOURNAL' },
  { label: $t('report.businessData'), value: 'BUSINESS_DATA' },
  { label: $t('common.report'), value: 'REPORT' },
  { label: $t('common.contact'), value: 'CONTACT' },
  { label: $t('common.automation'), value: 'AUTOMATION' },
  { label: $t('common.accountTreasury'), value: 'ACCOUNT' },
  { label: $t('report.reconciliation'), value: 'RECONCILIATION' },
  // { label: 'Report Config', value: 'REPORT_CONFIG' },
  { label: $t('common.projectSettings'), value: 'PROJECT_SETTING' }
])

const securityParams = reactive({
  page: 1,
  limit: 20,
  type: 'ALL',
  entityId: '0',
  member: 'ALL'
})

const typeEnum: any = {
  LOGIN: 'LOGIN',
  SOURCE: ['ADD_SOURCE', 'EDIT_SOURCE', 'DELETE_SOURCE', 'UPLOAD_CSV'],
  TRANSACTION: ['CATEGORY_TX', 'CATEGORY_TXS', 'UPDATE_TRANSACTION', 'EXPORT_TRANSACTION'],
  JOURNAL: [
    'ADD_JOURNAL',
    'EDIT_JOURNAL',
    'DELETE_JOURNAL',
    'POSTING',
    'ADD_JOURNAL_GROUP_CONFIG',
    'EDIT_JOURNAL_GROUP_CONFIG',
    'DELETE_JOURNAL_GROUP_CONFIG',
    'EXECUTE_JOURNAL_GROUP_CONFIG'
  ],
  BUSINESS_DATA: ['ADD_BUSINESS_DATA', 'ADD_BUSINESS_DATA_SOURCE', 'DELETE_BUSINESS_DATA_SOURCE'],
  REPORT: ['REGENERATE_REPORT'],
  CONTACT: ['ADD_CONTACT', 'UPDATE_CONTACT', 'EDIT_CONTACT', 'DELETE_CONTACT'],
  ACCOUNT: [
    'ADD_ENTITY_ACCOUNT',
    'EDIT_ENTITY_ACCOUNT',
    'DELETE_ENTITY_ACCOUNT',
    'ADD_TREASURY_BALANCE_CHECKPOINT',
    'DELETE_TREASURY_BALANCE_CHECKPOINT',
    'VERIFY_TREASURY_BALANCE',
    'EXPORT_TREASURY_BALANCE'
  ],
  AUTOMATION: [
    'ADD_CONFIG_RULE',
    'EDIT_CONFIG_RULE',
    'DELETE_CONFIG_RULE',
    'EXECUTE_CONFIG_RULE',
    'EXECUTE_TRADE_CONFIG_RULE',
    'EXECUTE_GAIN_LOSS_CONFIG_RULE',
    'EXECUTE_BUSINESS_DATA_CONFIG_RULE',
    'EXECUTE_BUSINESS_DATA_VALUATION_CONFIG_RULE',
    'EXECUTE_COUNTERPARTY_TRANSFORMER'
  ],
  REPORT_CONFIG: [
    'ADD_CHART_OF_ACCOUNT',
    'EDIT_CHART_OF_ACCOUNT',
    'DELETE_CHART_OF_ACCOUNT',
    'ADD_JOURNAL_TYPE',
    'EDIT_JOURNAL_TYPE',
    'DELETE_JOURNAL_TYPE',
    'ADD_AUXILIARY_TYPE',
    'EDIT_AUXILIARY_TYPE',
    'DELETE_AUXILIARY_TYPE'
  ],
  RECONCILIATION: [
    'ADD_RECONCILIATION_MATCH_SET',
    'EDIT_RECONCILIATION_MATCH_SET_DETAIL',
    'EDIT_RECONCILIATION_MATCH_SET_FILTER',
    'DELETE_RECONCILIATION_MATCH_SET',
    'ADD_RECONCILIATION_MATCH_RULE',
    'EDIT_RECONCILIATION_MATCH_RULE',
    'EXECUTE_RECONCILIATION_MATCH_SET',
    'DELETE_RECONCILIATION_MATCH_RECORD',
    'ADD_RECONCILIATION_MATCH_RECORD',
    'ADD_RECONCILIATION_TASK',
    'UPDATE_RECONCILIATION_TASK',
    'DELETE_RECONCILIATION_TASK',
    'ADD_RECONCILIATION_TASK_DATA_SET',
    'UPDATE_RECONCILIATION_TASK_DATA_SET',
    'DELETE_RECONCILIATION_TASK_DATA_SET',
    'EXECUTE_RECONCILIATION_RULE',
    'ADD_RECONCILIATION_MATCH',
    'ADD_RECONCILIATION_EXPLAIN',
    'DELETE_RECONCILIATION_EXPLAIN',
    'DELETE_RECONCILIATION_ADJUSTMENT',
    'ADD_RECONCILIATION_ADJUSTMENT'
  ],
  PROJECT_SETTING: [
    'ADD_ENTITY',
    'EDIT_ENTITY',
    'DELETE_ENTITY',
    'INVITE_MEMBER',
    'EDIT_MEMBER_PERMISSIONS',
    'REMOVE_MEMBER'
  ]
}

const tableData: ProjectSecurityLogType = reactive({
  list: [],
  total: 0
})

const entityOptions = computed(() => {
  let list: any = [{ label: t('common.allEntity'), value: '0' }]
  if (projectStore.projectEntityList.length) {
    list = list.concat(
      projectStore.projectEntityList.map((item: any) => ({ label: item.name, value: item.entityId, logo: item.logo }))
    )
  }
  console.log(list)
  return list
})

const memberOptions = computed(() => {
  let list: any = [{ label: t('common.allMember'), value: 'ALL' }]
  if (projectStore.projectMemberList.length) {
    list = list.concat(
      projectStore.projectMemberList
        .filter((i) => i.name !== '')
        .map((item: any) => ({ label: item.name, value: item.userId, email: item.email }))
    )
  }
  return list
})

const projectId = computed(() => {
  return String(route.params?.projectId)
})

const md5UserParams = computed(() => {
  return (userId: string) => {
    const md5UserId = userId.slice(0, 18)
    return md5(md5UserId)
  }
})

const activityContent = computed(() => {
  return (item: ProjectSecurityLogItemType) => {
    let content = ''
    switch (item.type) {
      case 'CREATE_SOURCE':
      case 'ADD_SOURCE':
        content = t('log.addSource', { sourceName: item.params?.sourceName ?? item.extra?.name })
        break
      case 'UPDATE_SOURCE':
      case 'EDIT_SOURCE':
        content = t('log.editSource', { sourceName: item.params?.sourceName ?? item.extra?.name })
        break
      case 'DELETE_SOURCE':
        content = t('log.deleteSource', { sourceName: item.extra?.name ?? item.params?.sourceName })
        break
      case 'UPLOAD_CSV':
        content = t('log.uploadCsv', { fileName: item.params?.fileName })
        break
      case 'CATEGORY_TX':
        content = t('log.categorizeTransaction', {
          txId: middleEllipsis(item.extra?.txId),
          category: item.extra?.journalTypeName
        })
        break
      case 'CATEGORY_TXS':
        content = t('log.batchCategorizeTransaction', {
          txIds:
            item.extra?.txIds.length > 3
              ? `${item.extra?.txIds
                  ?.slice(0, 3)
                  ?.map((row: any) => middleEllipsis(row))
                  ?.join(', ')}...`
              : item.extra?.txIds?.map((row: any) => middleEllipsis(row))?.join(', '),
          total: item.extra?.txIds?.length,
          category: item.extra?.journalTypeName
        })
        break
      case 'EXPORT_TRANSACTION':
        content = t('log.exportTransaction', {
          total: item?.extra?.name
        })
        break
      case 'ADD_JOURNAL':
        content = t('log.addJournal', {
          journalTypeName: item.extra?.journalTypeName,
          journalNo: item.extra?.journalNo
        })
        break
      case 'DELETE_JOURNAL':
        content = t('log.deleteJournal', {
          journalTypeName: item.extra?.journalTypeName,
          journalNo: item.extra?.journalNo
        })
        break
      case 'EDIT_JOURNAL':
        content = t('log.editJournal', {
          journalTypeName: item.extra?.journalTypeName,
          journalNo: item.extra?.journalNo
        })
        break
      case 'POSTING':
        content = t('button.postToLedger')
        break
      case 'ADD_JOURNAL_GROUP_CONFIG':
        content = t('log.addNewBusinessEvent', {
          name: item.extra?.name
        })
        break
      case 'EDIT_JOURNAL_GROUP_CONFIG':
        content = t('log.editBusinessEvent', {
          name: item.extra?.name
        })
        break
      case 'DELETE_JOURNAL_GROUP_CONFIG':
        content = t('log.deleteBusinessEvent', {
          name: item.extra?.name
        })
        break
      case 'EXECUTE_JOURNAL_GROUP_CONFIG':
        content = t('log.regeneratedBusinessEventsList')
        break
      case 'ADD_BUSINESS_DATA':
        content = t('log.addBusinessData', {
          name: item.extra?.name,
          journalNo: item.extra?.journalNo
        })
        break
      case 'ADD_BUSINESS_DATA_SOURCE':
        content = t('log.addBusinessDataSource', {
          name: item.extra?.name
        })
        break
      case 'DELETE_BUSINESS_DATA_SOURCE':
        content = t('log.deleteBusinessDataSource', {
          name: item.extra?.name
        })
        break
      case 'REGENERATE_REPORT':
        content = t('log.regenerateReports')
        break
      case 'ADD_CONTACT':
        content = t('log.addContact', { contactName: item.params?.name ?? item.extra?.name })
        break
      case 'BATCH_ADD_CONTACT':
        // eslint-disable-next-line no-case-declarations
        const addContactNameList = item.params.map((i: any) => i.name)
        content = t('log.batchAddContact', {
          contactNames:
            addContactNameList.length > 3
              ? `${addContactNameList?.slice(0, 3)?.join(', ')}...`
              : addContactNameList?.join(', '),
          total: addContactNameList?.length
        })
        break
      case 'EDIT_CONTACT':
      case 'UPDATE_CONTACT':
        content = t('log.editContact', { contactName: item.params?.name ?? item.extra?.name })
        break
      case 'DELETE_CONTACT':
        content = t('log.deleteContact', { contactName: item.params?.name ?? item.extra?.name })
        break
      case 'BATCH_DELETE_CONTACT':
        // eslint-disable-next-line no-case-declarations
        const contactNameList = item.params.map((i: any) => i.name)
        content = t('log.batchDeleteContact', {
          contactNames:
            contactNameList.length > 3 ? `${contactNameList?.slice(0, 3)?.join(', ')}...` : contactNameList?.join(', '),
          total: contactNameList?.length
        })
        break
      case 'CREATE_CONFIG_RULE':
      case 'ADD_CONFIG_RULE':
        content = t('log.addRule', { ruleName: item.params?.name ?? item.extra?.name })
        break
      case 'UPDATE_CONFIG_RULE':
      case 'EDIT_CONFIG_RULE':
        content = t('log.editRule', { ruleName: item.params?.name ?? item.extra?.name })
        break
      case 'DELETE_CONFIG_RULE':
        content = t('log.deleteRule', { ruleName: item.params?.name ?? item.extra?.name })
        break
      case 'GENERATE_ACTIVITY':
      case 'EXECUTE_CONFIG_RULE':
        content = t('log.manuallyExecuteRules', { action: `${t('report.transfer')} → ${t('report.Journal')}` })
        break
      case 'EXECUTE_TRADE_CONFIG_RULE':
        content = t('log.manuallyExecuteRules', { action: `${t('common.trade')} → ${t('report.Journal')}` })
        break
      case 'EXECUTE_GAIN_LOSS_CONFIG_RULE':
        content = t('log.manuallyExecuteRules', {
          action: `${t('project.costBasis.gainOrLoss')} → ${t('report.Journal')}`
        })
        break
      case 'EXECUTE_COUNTERPARTY_TRANSFORMER':
        content = t('log.manuallyExecuteRules', { action: t('report.identifyCounterparty') })
        break
      case 'EXECUTE_BUSINESS_DATA_CONFIG_RULE':
        if (item.params?.name ?? item.extra?.name) {
          content = t('log.manuallyExecuteBusinessDataRule', { ruleName: item.params?.name ?? item.extra?.name })
        } else {
          content = t('log.manuallyExecuteRules', { action: `${t('report.businessData')} → ${t('report.Journal')}` })
        }
        break
      case 'EXECUTE_BUSINESS_DATA_VALUATION_CONFIG_RULE':
        if (item.extra?.type === 'NORMAL' && item.extra?.name) {
          content = t('log.manuallyExecuteBusinessDataRule', { ruleName: item.extra?.name })
        } else {
          content = t('log.manuallyExecuteRules', { action: `${t('report.businessData')} → ${t('common.valuation')}` })
        }
        break
      case 'CREATE_ENTITY_ACCOUNT':
      case 'ADD_ENTITY_ACCOUNT':
        content = t('log.addAccount', { accountName: item.params?.name ?? item.extra?.name })
        break
      case 'UPDATE_ENTITY_ACCOUNT':
      case 'EDIT_ENTITY_ACCOUNT':
        content = t('log.editAccount', { accountName: item.params?.name ?? item.extra?.name })
        break
      case 'DELETE_ENTITY_ACCOUNT':
        content = t('log.deleteAccount', { accountName: item.params?.name ?? item.extra?.name })
        break
      case 'BATCH_DELETE_ENTITY_ACCOUNT':
        // eslint-disable-next-line no-case-declarations
        const accountNameList = item.params.map((i: any) => i.name)
        content = t('log.batchDeleteAccount', {
          accountNames:
            accountNameList.length > 3 ? `${accountNameList?.slice(0, 3)?.join(', ')}...` : accountNameList?.join(', '),
          total: accountNameList?.length
        })
        break
      case 'ADD_TREASURY_BALANCE_CHECKPOINT':
        content = t('log.addTreasuryBalanceCheckpoint', {
          accountName: item.extra?.name,
          balance: item.extra?.balance,
          currency: item.extra?.currency,
          datetime: dayjs.tz(item.extra?.datetime, 'UTC').format('YYYY/MM/DD HH:mm:ss')
        })
        break
      case 'DELETE_TREASURY_BALANCE_CHECKPOINT':
        content = t('log.deleteTreasuryBalanceCheckpoint', {
          accountName: item.extra?.name,
          datetime: dayjs.tz(item.extra?.datetime, 'UTC').format('YYYY/MM/DD HH:mm:ss')
        })
        break
      case 'VERIFY_TREASURY_BALANCE':
        content = t('log.verifyTreasuryBalance')
        break
      case 'EXPORT_TREASURY_BALANCE':
        content = t('log.exportTreasuryBalance')
        break
      case 'ADD_CHART_OF_ACCOUNT':
        content = t('log.addChartOfAccount', {
          name: item.extra?.name
        })
        break
      case 'EDIT_CHART_OF_ACCOUNT':
        content = t('log.editChartOfAccount', {
          name: item.extra?.name
        })
        break
      case 'DELETE_CHART_OF_ACCOUNT':
        content = t('log.deleteChartOfAccount', {
          name: item.extra?.name
        })
        break
      case 'ADD_JOURNAL_TYPE':
        content = t('log.addJournalType', {
          name: item.extra?.name
        })
        break
      case 'EDIT_JOURNAL_TYPE':
        content = t('log.editJournalType', {
          name: item.extra?.name
        })
        break
      case 'DELETE_JOURNAL_TYPE':
        content = t('log.deleteJournalType', {
          name: item.extra?.name
        })
        break
      case 'ADD_AUXILIARY_TYPE':
        content = t('log.addAuxiliaryType', {
          name: item.extra?.name
        })
        break
      case 'EDIT_AUXILIARY_TYPE':
        content = t('log.editAuxiliaryType', {
          name: item.extra?.name
        })
        break
      case 'DELETE_AUXILIARY_TYPE':
        content = t('log.deleteAuxiliaryType', {
          name: item.extra?.name
        })
        break
      case 'ADD_RECONCILIATION_MATCH_SET':
        content = t('log.addReconciliationMatchSet', {
          name: item.extra?.name
        })
        break
      case 'DELETE_RECONCILIATION_MATCH_SET':
        content = t('log.deleteReconciliationMatchSet', {
          name: item.extra?.name
        })
        break
      case 'EDIT_RECONCILIATION_MATCH_SET_DETAIL':
        content = t('log.editReconciliationMatchSetDetail', {
          name: item.extra?.name
        })
        break
      case 'EDIT_RECONCILIATION_MATCH_SET_FILTER':
        content = t('log.editReconciliationMatchSetFilter', {
          name: item.extra?.name
        })
        break
      case 'ADD_RECONCILIATION_MATCH_RULE':
        content = t('log.addReconciliationMatchSetRule', {
          ruleName: item.extra?.name,
          setNo: item.extra?.taskNo
        })
        break
      case 'EDIT_RECONCILIATION_MATCH_RULE':
        content = t('log.editReconciliationMatchSetRule', {
          ruleName: item.extra?.name,
          setNo: item.extra?.taskNo
        })
        break
      case 'EXECUTE_RECONCILIATION_MATCH_SET':
        content = t('log.executeReconciliationMatchSet', {
          name: item.extra?.name
        })
        break
      case 'DELETE_RECONCILIATION_MATCH_RECORD':
        content = t('log.deleteReconciliationMatchRecord', {
          name: item.extra?.matchNo
        })
        break
      case 'ADD_RECONCILIATION_MATCH_RECORD':
        content = t('log.addReconciliationMatchRecord', {
          name: item.extra?.name
        })
        break
      case 'RECALCULATE_COST_BASIS':
        content = t('log.recalculateCostBasis', {
          name: item.extra?.name
        })
        break
      case 'ADD_ENTITY':
        content = t('log.addEntity', {
          name: item.extra?.name
        })
        break
      case 'EDIT_ENTITY':
        content = t('log.editEntity', {
          name: item.extra?.name
        })
        break
      case 'DELETE_ENTITY':
        content = t('log.deleteEntity', {
          name: item.extra?.name
        })
        break
      case 'INVITE_MEMBER':
        content = t('log.inviteMember', {
          name: item.extra?.name
        })
        break
      case 'EDIT_MEMBER_PERMISSIONS':
        content = t('log.editMemberPermission', {
          name: item.extra?.name
        })
        break
      case 'REMOVE_MEMBER':
        content = t('log.removeMember', {
          name: item.extra?.name
        })
        break
      case 'LOGIN':
        content = t('button.signIn')
        break
      case 'ADD_RECONCILIATION_TASK':
        content = t('reconciliation.createReconciliationTaskTip', {
          name: item.extra?.name
        })
        break
      case 'UPDATE_RECONCILIATION_TASK':
        content = t('reconciliation.modifiedReconciliationTaskTip', {
          name: item.extra?.name
        })
        break
      case 'DELETE_RECONCILIATION_TASK':
        content = t('reconciliation.deletedReconciliationTaskTip', {
          name: item.extra?.name
        })
        break
      case 'ADD_RECONCILIATION_TASK_DATA_SET':
        content = t('reconciliation.addDateSetForReconciliationTaskTip', {
          dataName: item.extra.name,
          name: item.extra?.taskSideName || ''
        })
        break
      case 'UPDATE_RECONCILIATION_TASK_DATA_SET':
        content = t('reconciliation.modifiedDateSetForReconciliationTaskTip', {
          dataName: item.extra.name,
          name: item.extra?.taskSideName || ''
        })
        break
      case 'DELETE_RECONCILIATION_TASK_DATA_SET':
        content = t('reconciliation.deleteDateSetForReconciliationTaskTip', {
          dataName: item.extra.name,
          name: item.extra?.taskSideName || ''
        })
        break
      case 'EXECUTE_RECONCILIATION_RULE':
        content = t('reconciliation.automaticRuleExecutionTip', {
          name: item.extra?.taskName || ''
        })
        break
      case 'ADD_RECONCILIATION_MATCH':
        content = t('reconciliation.manuallyAddedMatchRecord', {
          name: item.extra?.matchNo
        })
        break
      case 'ADD_RECONCILIATION_EXPLAIN':
        content = t('reconciliation.explainedDateSetForReconciliationTaskTip', {
          dataName: item.extra.dataId,
          name: item.extra?.taskName
        })
        break
      case 'DELETE_RECONCILIATION_EXPLAIN':
        content = t('reconciliation.clearedExplanationForReconciliationTaskTip', {
          dataName: item.extra.dataId,
          name: item.extra?.taskName
        })
        break
      case 'DELETE_RECONCILIATION_ADJUSTMENT':
        content = t('reconciliation.deleteJournalForReconciliationTaskTip', {
          journalName: item.extra.journalNoList,
          name: item.extra?.taskName
        })
        break
      case 'ADD_RECONCILIATION_ADJUSTMENT':
        content = t('reconciliation.createJournalForReconciliationTaskTip', {
          journalName: item.extra.journalNoList,
          name: item.extra?.taskName
        })
        break
      default:
        content = ''
        break
    }
    return content
  }
})

const changeAvatarColor: any = (val: string) => {
  const lastStr = val.substr(val.length - 1, 1)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(lastStr))) {
    return changeAvatarColor(val.substr(0, val.length - 1))
  }
  return avatarColor[lastStr]
}

const fetchProjectSecurityLog = async () => {
  try {
    tableLoading.value = true
    const params: ProjectSecurityLogParamsType = {
      page: securityParams.page,
      limit: securityParams.limit
    }
    if (securityParams.type !== 'ALL') {
      params.types = typeEnum[securityParams.type]
    }
    if (securityParams.member !== 'ALL') {
      params.userId = securityParams.member
    }
    if (securityParams.entityId !== '0') {
      params.entityId = securityParams.entityId
    }
    const { data } = await ProjectApi.getProjectLog(projectId.value, params)
    tableData.list = data.list
    tableData.total = data.total
    securityTableRef.value?.setScrollTop(0)
    securityTableRef.value?.setScrollLeft(0)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    tableLoading.value = false
  }
}

const onChangeMember = (item: any) => {
  securityParams.member = item.value
  securityParams.page = 1
  memberPopover.value?.hide()
  fetchProjectSecurityLog()
}

const onChangeType = (item: any) => {
  securityParams.type = item.value
  securityParams.page = 1
  typePopover.value?.hide()
  fetchProjectSecurityLog()
}

const onChangeEntity = (item: any) => {
  securityParams.entityId = item.value
  securityParams.page = 1
  entityPopover.value?.hide()
  fetchProjectSecurityLog()
}

const onChangePage = (page: number) => {
  securityParams.page = page
  fetchProjectSecurityLog()
}

const onChangePageSize = (limit: number) => {
  securityParams.limit = limit
  securityParams.page = 1
  fetchProjectSecurityLog()
}

onMounted(() => {
  fetchProjectSecurityLog()
})
</script>
<style lang="scss">
.elv-project-setting-security {
  width: v-bind('props.width');
}

.elv-project-setting-security-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-project-setting-security-table-header {
    background: #eef4fb;

    .elv-project-setting-security-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;

      &:last-child {
        border-right: 0px;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  // .elv-project-setting-security-table-row:last-child {
  //   .elv-project-setting-security-table-row__cell {
  //     border-bottom: 0px;
  //   }
  // }

  .elv-project-setting-security-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-project-setting-security-table-row__cell-activity {
      font-weight: 500;
    }

    .elv-project-setting-security-table-row__cell-member {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        background: #f6f6f6;
        border-radius: 50%;
        display: block;
        margin-right: 8px;
      }
    }

    .elv-project-setting-security-table-row__cell-date {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #0e0f11;
    }
  }
}

.elv-project-setting-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}

.elv-project-setting-security-popper {
  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    img {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: fit-content;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 2px 4px 2px 4px;
        border-radius: 3px;
        border: 1px solid #dde1e6;
        margin-left: 10px;
        font-size: 11px;
        color: #d0d4d9;
      }
    }

    &.hover {
      background: #f9fafb;
    }
  }
}

.elv-project-setting-security-screen {
  display: flex;
  padding: 7px 20px;

  .elv-project-setting-security-screening-button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px;
    height: 30px;
    border: 1px solid #edf0f3;
    border-radius: 16px;
    cursor: pointer;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-right: 8px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.is-disabled {
      cursor: not-allowed;
    }

    .elv-project-setting-security-screening-button__icon {
      fill: #96a4ca;
      margin-right: 4px;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):active {
      border: 1px solid #5e85eb;

      .elv-project-setting-security-screening-button__icon {
        fill: #5e85eb;
      }
    }
  }
}

.el-popover.elv-project-setting-security-screening-popper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: 190px;
  width: auto !important;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .el-scrollbar__view,
  .el-scrollbar {
    width: 100%;
  }

  li {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    color: #0e0f11;

    &:hover {
      background: #f9fafb;
    }

    img {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }

    svg {
      margin-right: 10px;
      fill: #5e85eb;
    }
  }
}
</style>
